import org.w3c.dom.events.Event
import org.w3c.dom.get
import org.w3c.xhr.JSON
import org.w3c.xhr.XMLHttpRequest
import org.w3c.xhr.XMLHttpRequestResponseType
import pl.com.pixel.dynaroles.js.model.RoleListExp
import pl.com.pixel.dynaroles.js.model.Rule
import pl.com.pixel.dynaroles.js.model.RuleResponse
import kotlin.browser.document
import kotlin.coroutines.experimental.*
import kotlin.js.*

/**
 * Created by maciek on 26.07.17.
 */


external interface CompiledSpel {
    fun eval(root:Json,locals:Json):Any?
}

fun CompiledSpel.eval(vararg locals:Pair<String,Any?>) = eval(json(),json(*locals))

external interface Spel2Js {
    fun compile(spel:String):CompiledSpel
    fun eval(compiledSpel:CompiledSpel,ctx:dynamic):Any?
}

/**
 * wyrażenie logiczne to spel lub lista ról
 */
class Expression(val  compiledSpel: CompiledSpel?=null, val roleListExp: RoleListExp?=null)
/**
 * Tablica skompilowanych zestawów reguł
 */
@JsName("compiledExpressions")
val compiledExpressions= mutableMapOf<String,Array<Expression>>()
/**
 * Cache
 */
val checkCache= mutableMapOf<String,Boolean>()
/**
 * Główna f-cj aprawdzająca uprawnienia
 */
@JsName("check")
fun check(name:String,userRoles:Array<String>?,ctx:Json? = null):Boolean {
    //console.log("check", name, userRoles)
    //console.log("compiledExpressions", compiledExpressions)
    val spels=compiledExpressions.get(name)
    if(spels==null) {
        console.log("Dyna-roles: no rule $name. Reject.")
        return false
    }
    /**
     * Nie cacheujemy wyniku gdy był do reguły podany kontekst
     */
    val cacheKey=if(ctx==null) "$name:${userRoles?.joinToString("&")?:""}" else null
    if(cacheKey!=null) checkCache.get(cacheKey)?.let { return it}
    val decision = spels.asSequence().map { s:Expression ->
        val roles = when {
            s.compiledSpel!=null -> {
                val result = s.compiledSpel.eval(json(),ctx?:json())
                //console.log("Execute spel",name,result)
                when {
                    result!=null && result::class.js.name.equals("string",true) -> arrayOf<String>(result as String)
                    result!=null && result::class.js.name.equals("number",true) -> if((result as Number)!=0) arrayOf("+") else arrayOf("-")
                    result!=null && result::class.js.name.equals("boolean",true) -> if(result as Boolean) arrayOf("+") else arrayOf("-")
                    result!=null -> result as Array<String>
                    else -> emptyArray<String>()
                }
            }
            s.roleListExp!=null -> s.roleListExp.roles.map { "${if(it.reject) "-" else "+"}${it.role.trim()}" }.toTypedArray()
            else -> emptyArray()
        }
        //console.log("Resulted roles for rule ",name, roles)
        when {
            roles.isEmpty() -> null
            roles.contains("-") -> false
            roles.contains("+") -> true
            userRoles!=null && userRoles.filter { roles.contains("-$it") }.any() -> false
            userRoles!=null && userRoles.filter { roles.contains("+$it") }.any() -> true
            else -> null
        }
    }.filterNotNull().firstOrNull()?:false
    //console.log("Decision for",userRoles,decision)
    if(cacheKey!=null) checkCache.put(cacheKey,decision)
    return decision
}

//fun checkAll() {
//    //console.log("CheckAll")
//    compiledExpressions.keys.forEach { name ->
//        //console.log("klucz",name)
//        document.getElementsByClassName(name).let { res ->
//            (0..res.length-1).forEach {
//                val elem=res.get(it)
//                if(elem!=null) {
//                    //console.log( elem.className)
//                    val roles=elem.className.split(Regex("[, ]")).map { it.trim() }.toTypedArray()
//                    val decision = check(name,roles)
//                    elem.textContent="$decision"
//                }
//            }
//        }
//    }
//}

internal fun loadRulesInternal(url:String,onErr:(Throwable)->Unit,onLoad:(RuleResponse)->Unit) {
    val req = XMLHttpRequest()
    req.onerror = {
        onErr(Throwable(it.toString()))
    }
    req.onload = {
        event: Event ->
//        console.log("loadRules event",event)

        val resp = (event.target as XMLHttpRequest).response
        val parse = if(resp!=null && resp::class==String::class) JSON.parse<RuleResponse>(resp as String)
        else resp.asDynamic()
        val respData:RuleResponse = parse //.asDynamic()
//        console.log("loadRules response",JSON.stringify(respData))
        onLoad(respData)
        ""
    }
    req.open("GET",url,true)
    req.responseType=XMLHttpRequestResponseType.JSON
    req.send()
    //console.log("Request sent")
}

/**
 * ładowanie reguł z zadanego url-a
 */
@JsName("loadRules")
fun loadRules(url:String="/dyna-roles/api/getRules"):Promise<Boolean> {
    val spel2js: Spel2Js = js("window.spel2js.SpelExpressionEvaluator")
    val p = Promise<Boolean>({
        ok,err ->
        loadRulesInternal(url,{
            err(it)
        }) {
            r ->
            //console.log("loadRules rules",JSON.stringify(r))
            r.rules.map { rr ->
                val c = rr.expressions.map {
                    val s=it.spel?.spel
                    when {
                        s!=null -> Expression(compiledSpel = spel2js.compile(s))
                        it.roleList!=null -> Expression(roleListExp = it.roleList)
                        else -> null
                    }
                }.filterNotNull()
                compiledExpressions.put("${rr.name}",c.toTypedArray())
                ok(true)
            }
        }

    })
    return p
}

suspend fun <T> Promise<T>.await(): T = suspendCoroutine { cont ->
    then({ cont.resume(it) }, { cont.resumeWithException(it) })
}

fun launch(block: suspend () -> Unit) {
    block.startCoroutine(object : Continuation<Unit> {
        override val context: CoroutineContext get() = EmptyCoroutineContext
        override fun resume(value: Unit) {}
        override fun resumeWithException(e: Throwable) { println("Coroutine failed: $e") }
    })
}
fun main(args: Array<String>) {
    launch {
        loadRules().run { console.log(this.await()) }
        console.log("Dyna-roles rules were loaded")
    }

}